import type { FC } from 'react';
import { createContext, useMemo } from 'react';

import { ThemeOptions, ThemeProvider } from '@material-ui/core';

import { THEMES } from 'src/constants';
import useAuth from 'src/hooks/useAuth';
import useSettings from 'src/hooks/useSettings';
import { createCustomTheme } from 'src/theme';
import { internalAuthPlatforms } from 'src/utils/auth/authPlatforms';

import { useDialmakerDesktopUtils } from './dialmakerDesktop/DialmakerDesktopUtilsContext';

enum FontSizes {
  FS_14 = '14px',
  FS_16 = '16px',
  FS_18 = '18px',
}

enum BackgroundColors {
  DESKTOP_APP = '#FFFFFF',
  MY_PAGES = '#F8F6F4'
}

export interface ThemeSettingsProps {
}

const ThemeSettingsContext = createContext<ThemeSettingsProps>(null);

export const ThemeSettingsProvider: FC = (props) => {
  const { children } = props;

  const { platform } = useAuth();
  const { settings } = useSettings();
  const { isDesktopDialmakerApp } = useDialmakerDesktopUtils();

  const isInternal = internalAuthPlatforms.includes(platform);

  const fs = useMemo(() => {
    if (isDesktopDialmakerApp) return FontSizes.FS_14;
    if (isInternal) return FontSizes.FS_16;

    return FontSizes.FS_18;
  }, [isInternal, isDesktopDialmakerApp]);

  const themeAdditionalOptions = useMemo(() => {
    const options: Record<string, ThemeOptions> = {
      [THEMES.LIGHT]: {},
      [THEMES.DARK]: {},
    };

    if (!isInternal) options[THEMES.LIGHT].palette = { background: { default: BackgroundColors.MY_PAGES } };
    if (isDesktopDialmakerApp) options[THEMES.LIGHT].palette = { background: { default: BackgroundColors.DESKTOP_APP } };

    return options;
  }, [isDesktopDialmakerApp]);

  const theme = createCustomTheme({
    theme: settings.theme,
    globalFontSize: fs,
    themeAdditionalOptions,
  });

  const value: ThemeSettingsProps = useMemo(() => ({
  }), []);

  return (
    <ThemeSettingsContext.Provider value={value}>
      <ThemeProvider theme={theme}>
        {children}
      </ThemeProvider>
    </ThemeSettingsContext.Provider>
  );
};

export default ThemeSettingsContext;
