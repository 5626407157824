import { createContext, FC, useContext, useEffect, useMemo, useState } from 'react';

import moment from 'moment';
import { v4 as uuid } from 'uuid';

import { ConsentsPayload } from 'src/api/zrm';
import useApi from 'src/hooks/useApi';
import logger from 'src/utils/logger';

import { useCustomerData } from '../customerData/CustomerDataContext';

export interface TestConsent {
  hasConsent: boolean;
  lastSeen: Date;
}

export interface MarketingConsentContextProps {
  showConstentBox: boolean;
  closeConsent: (consentChecked: boolean) => void;
}

const MarketingConsentContext = createContext<MarketingConsentContextProps>({ showConstentBox: false, closeConsent: () => {} });

const MarketingConsentContextProvider: FC = (props) => {
  const { children } = props;
  const { api } = useApi();
  const { data } = useCustomerData();

  const [consentInfo, setConsentInfo] = useState<TestConsent>({ hasConsent: true, lastSeen: new Date() });

  const saveConsent = useMemo(() => async (consentChecked: boolean) => {
    const requestId = uuid();

    const payload: ConsentsPayload = consentChecked ? ({ consents: { customer_club_marketing: true, email_marketing: true, sms_marketing: true } }) : ({ dismissed_consents_popup: true });

    try {
      await api.myPages.upsertMarketingConsentMyPagesMarketingConsentsPost(
        payload, { headers: { 'X-Request-ID': requestId }, cancelToken: requestId },
      );
    } catch (error) {
      if (error?.name === 'AbortError') return;

      logger.error(error, {
        source: 'MarketingConsentContext',
        description: 'Save Marketing Consent',
        requestId,
      });
    }
  }, []);

  const closeConsent = useMemo(() => (consentChecked: boolean) => {
    setConsentInfo({ hasConsent: consentChecked, lastSeen: new Date() });

    saveConsent(consentChecked);
  }, [saveConsent]);

  useEffect(() => {
    if (data) setConsentInfo({
      hasConsent: data?.consents?.customer_club_marketing || data?.consents?.email_marketing || data?.consents?.sms_marketing,
      lastSeen: data?.consents_popup_dismissed_timestamp ? moment(data?.consents_popup_dismissed_timestamp).toDate() : null,
    });
  }, [data]);

  const showConstentBox = useMemo(() => {
    if (consentInfo.hasConsent) return false;

    if (consentInfo.lastSeen && moment().diff(moment(consentInfo.lastSeen), 'days') < 30) return false;

    return true;
  }, [consentInfo]);

  return (
    <MarketingConsentContext.Provider
      value={{
        showConstentBox, closeConsent,
      }}
    >
      {children}
    </MarketingConsentContext.Provider>
  );
};

const useMarketingConsentContext = () => useContext(MarketingConsentContext);

export { MarketingConsentContextProvider, useMarketingConsentContext };
