import { FC, useMemo } from 'react';

import { AppBar, CircularProgress, Container, Grid, Typography, experimentalStyled, useTheme, withStyles } from '@material-ui/core';
import { useTranslation } from 'react-i18next';

import Norway from 'src/components/shared/country/Norway';
import Sweden from 'src/components/shared/country/Sweden';
import { SingleValueListItem, useCustomerZMSContext } from 'src/contexts/CustomerZMSContext';

const StyledTypography = withStyles({
  root: {
    fontSize: '0.8rem',
  },
})(Typography);

export const StyledAppBar = experimentalStyled(AppBar)(
  ({ theme }) => (
    {
      ...(
        theme.palette.mode === 'light' && {
          backgroundColor: theme.palette.background.paper,
          color: '#000',
          borderTop: '2px solid #888C92',
        }
      ),
      ...(
        theme.palette.mode === 'dark' && {
          backgroundColor: theme.palette.background.paper,
          borderTop: '2px solid #eee',
        }
      ),
    }
  ),
);

const CustomerPagesFooter: FC = () => {
  const { t } = useTranslation();
  const theme = useTheme();

  const { getZmsValueByKey, loading } = useCustomerZMSContext();

  const customerSupportPhone = getZmsValueByKey('customer_support_phone') as SingleValueListItem;
  const openingHours = getZmsValueByKey('opening_hours') as SingleValueListItem[];
  const email = getZmsValueByKey('customer_support_email') as string;

  const images = useMemo(() => {
    if (theme.palette.mode === 'light') return {
      bankId: '/static/logo-bank-id-se-light.svg',
      swedsec: '/static/swedsec.svg',
      uc: '/static/uc.svg',
    };

    return {
      bankId: '/static/logo-bank-id-se-dark.svg',
      swedsec: '/static/swedsec-dark.svg',
      uc: '/static/uc-dark.svg',

    };
  }, [theme?.palette?.mode]);

  return (
    <StyledAppBar
      color="primary"
      sx={{ bottom: 0, height: '100%', minHeight: '88px', position: 'relative' }}
    >
      <Container
        maxWidth="lg"
        sx={{
          p: 1,
        }}
      >
        <Grid
          container
          sx={{ alignItems: 'flex-start', justifyContent: 'space-between', px: 1 }}
        >
          <Grid
            item
            xs={6}
          >
            <StyledTypography>
              {t('Contact us')}
              :
            </StyledTypography>
            <StyledTypography sx={{ '& a': { color: 'inherit' } }}>
              <a href={`tel:${customerSupportPhone?.value}`}>{customerSupportPhone?.label}</a>
            </StyledTypography>
            <StyledTypography sx={{ '& a': { color: 'inherit' } }}>
              <a href={`mailto:${email}`}>{email}</a>
            </StyledTypography>
          </Grid>
          <Grid
            item
            xs={6}
            sx={{ textAlign: 'right' }}
          >
            <StyledTypography>
              {t('Opening times')}
              :
            </StyledTypography>
            {openingHours?.map((openingHour) => (
              <StyledTypography>
                {openingHour.label}
                :
                {' '}
                {openingHour.value}
              </StyledTypography>
            ))}
          </Grid>
          {loading && (
            <Grid
              item
              xs={12}
              sx={{ textAlign: 'center' }}
            >
              <CircularProgress
                size={20}
                color="inherit"
              />
            </Grid>
          )}
          <Grid
            item
            xs={12}
            sx={{ display: 'flex', alignItems: 'center', justifyContent: 'center', gap: 2 }}
          >
            <Sweden>
              <img
                src={images.bankId}
                height="20"
                width="auto"
                alt="BankID"
              />
              <img
                src={images.swedsec}
                height="20"
                width="auto"
                alt="swedsec"
              />
              <img
                src={images.uc}
                height="40"
                width="auto"
                alt="uc"
              />
            </Sweden>
            <Norway>
              <img
                src="/static/gr_footer_logo.svg"
                height="30"
                width="auto"
                alt="gr"
              />
            </Norway>
          </Grid>
        </Grid>
      </Container>
    </StyledAppBar>
  );
};

export default CustomerPagesFooter;
