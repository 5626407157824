import { FC, useEffect, useMemo, useState } from 'react';
import { unstable_batchedUpdates } from 'react-dom';

import { v4 } from 'uuid';

import { Country, CreateInsuranceCustomer, InsuranceCustomer, InsuranceGroup, InsuranceStatus, InsuranceSubstatus, InsuranceTerms } from 'src/api/zrm';
import useApi from 'src/hooks/useApi';
import useSettings from 'src/hooks/useSettings';
import logger from 'src/utils/logger';

import CustomerInsuranceDataContext, { CustomerInsuranceDataProps } from './CustomerInsuranceContext';
import { useCustomerData } from '../customerData/CustomerDataContext';

export const UnauthenticatedCustomerInsuranceDataProvider: FC = (props) => {
  const { children } = props;

  const { api } = useApi();
  const { country } = useSettings();
  const { setData: setCustomerData } = useCustomerData();

  const [dataLoading, setDataLoading] = useState({
    done: false,
    ongoing: false,
  });

  const [rules, setRules] = useState<Array<InsuranceTerms>>([]);
  const [data, setData] = useState<{ loading: boolean, value: Array<InsuranceCustomer>, done: boolean }>({ loading: false, value: [], done: false });
  const [encryptedInsuranceId, setEncryptedInsuranceId] = useState<string>('');
  const [pspIframe, setPspIframe] = useState<string>(null);
  const [insuranceId, setInsuranceId] = useState<string>(null);

  const getData = useMemo(() => async (requestId = v4()) => {
    if (!insuranceId) return () => {};

    setData((p) => ({ ...p, loading: true, done: false }));
    setDataLoading((p) => ({ ...p, loading: true, done: false }));

    try {
      const v = await api.myPages.getInsuranceMyPagesInsuranceGet({ id: insuranceId }, { headers: { 'X-Request-ID': requestId }, cancelToken: requestId });

      unstable_batchedUpdates(() => {
        setData({ loading: false, value: [v.data.insurance], done: true });
        setRules([v.data.rules]);
        setCustomerData(v.data.customer as any);
      });
    } catch (e) {
      if (e?.name === 'AbortError') return () => {};

      setData((p) => ({ ...p, loading: false, done: false }));

      logger.error(e, { source: 'UnauthenticatedCustomerInsuranceDataProvider', description: 'Get data', requestId });
    }

    setDataLoading((p) => ({ ...p, loading: false, done: true }));

    return () => api.abortRequest(requestId);
  }, [api, insuranceId]);

  const createInsurance = useMemo(() => async (payload: CreateInsuranceCustomer) => {
    const requestId = v4();
    const resp = await api.myPages.confirmInsuranceTermsMyPagesInsurancesConfirmPost({
      encrypted_insurance_id: insuranceId,
      fail_url: payload.fail_url,
      success_url: payload.success_url,
      email: payload.email,
      phone_number: payload.phone_number,
      insured_amount: payload.insured_amount,
      premium: payload.premium_amount,
    },
    { headers: { 'X-Request-ID': requestId }, cancelToken: requestId });
    setData((p) => ({ ...p, loading: false, done: true }));

    if (resp.data.psp_iframe) setPspIframe(resp.data.psp_iframe);
    if (resp.data.psp_external_url) window.location.href = resp.data.psp_external_url;

    return resp.data;
  }, [api, insuranceId]);

  useEffect(() => {
    const requestId = v4();
    getData(requestId);

    return () => {
      api.abortRequest(requestId);
    };
  }, [getData, api]);

  useEffect(() => {
    // We keep this mounted in different routes, so its easier to get this value once and don't care about it later
    const urlParams = new URLSearchParams(window.location.search);
    const myParam = urlParams.get('id');

    setInsuranceId(myParam);
  }, []);

  const value: CustomerInsuranceDataProps = useMemo(() => {
    const safetyInsurances = data.value.filter((v) => v.insurance_group === InsuranceGroup.SAFETY_INSURANCE);
    const pendingSafetyInsurances = safetyInsurances.filter((v) => v.status === InsuranceStatus.INACTIVE && v.substatus === InsuranceSubstatus.WAITING_FOR_CUSTOMER_ACCEPTANCE);

    const defaultInsuredAmount = country === Country.SE ? 3300 : 3400;

    return {
      getData,
      createInsurance,

      dataLoading: dataLoading.ongoing || data.loading,
      dataLoaded: dataLoading.done && data.done,
      rules,
      insurances: data.value,

      insuredAmount: pendingSafetyInsurances.length ? pendingSafetyInsurances[0].insured_amount : defaultInsuredAmount,
      encryptedInsuranceId,
      setEncryptedInsuranceId,

      pspIframe,
    };
  }, [getData, dataLoading, dataLoading, data, rules, country, encryptedInsuranceId, pspIframe, createInsurance]);

  return (
    <CustomerInsuranceDataContext.Provider value={value}>
      {children}
    </CustomerInsuranceDataContext.Provider>
  );
};
