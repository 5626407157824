import { createContext, Dispatch, FC, SetStateAction, useContext, useEffect, useMemo, useState } from 'react';

import { v4 } from 'uuid';

import { AcceptTermsResponse, Country, CreateInsuranceCustomer, InsuranceCustomer, InsuranceGroup, InsuranceStatus, InsuranceSubstatus, InsuranceTerms } from 'src/api/zrm';
import useApi from 'src/hooks/useApi';
import useAuth from 'src/hooks/useAuth';
import useSettings from 'src/hooks/useSettings';
import logger from 'src/utils/logger';

import { useCustomerData } from '../customerData/CustomerDataContext';

export interface CustomerInsuranceDataProps {
  dataLoading: boolean;
  dataLoaded: boolean;

  getData: (requestId?: string) => void;
  createInsurance: (payload: CreateInsuranceCustomer) => Promise<InsuranceCustomer | AcceptTermsResponse>;

  insuredAmount: number;
  rules: Array<InsuranceTerms>;

  encryptedInsuranceId: string;
  setEncryptedInsuranceId: Dispatch<SetStateAction<string>>;

  pspIframe?: string;
  setPspIframe?: Dispatch<SetStateAction<string>>;
  insurances: Array<InsuranceCustomer>;

  setBlancoMonthlyCost?: Dispatch<SetStateAction<number>>;
}

const CustomerInsuranceDataContext = createContext<CustomerInsuranceDataProps>(null);

export const CustomerInsuranceDataProvider: FC = (props) => {
  const { children } = props;

  const { api } = useApi();
  const { isAuthenticated } = useAuth();
  const { country } = useSettings();
  const customerData = useCustomerData();

  const [dataLoading, setDataLoading] = useState({
    done: false,
    ongoing: false,
  });

  const [rules, setRules] = useState<Array<InsuranceTerms>>([]);
  const [data, setData] = useState<{ loading: boolean, value: Array<InsuranceCustomer>, done: boolean }>({ loading: false, value: [], done: false });
  const [encryptedInsuranceId, setEncryptedInsuranceId] = useState<string>('');
  const [pspIframe, setPspIframe] = useState<string>(null);
  const [blancoMonthlyCost, setBlancoMonthlyCost] = useState<number>(null);

  const getInsuranceRulesData = useMemo(() => async (requestId = v4()) => {
    setDataLoading((p) => ({ ...p, ongoing: true }));

    try {
      const resp = await api.myPages.insurancesRulesMyPagesInsurancesRulesGet(
        { cancelToken: requestId, headers: { 'X-Request-ID': requestId } },
      );

      setRules(resp.data);
    } catch (e) {
      if (e?.name === 'AbortError') return;

      let errorDescription = 'Get customer insurance rules';

      if (e.status === 404) errorDescription = '404 Customer not found - Get customer insurance rules';

      logger.error(e, { source: 'CustomerInsuranceContext', description: errorDescription, requestId });
    }
    setDataLoading((p) => ({ ...p, ongoing: false, done: true }));
  }, [api]);

  const getData = useMemo(() => async (requestId = v4()) => {
    setData((p) => ({ ...p, loading: true, done: false }));
    try {
      const v = await api.myPages.activeInsuranceByPniMyPagesInsurancesGet({ headers: { 'X-Request-ID': requestId }, cancelToken: requestId });
      setData({ loading: false, value: v.data, done: true });
    } catch (e) {
      if (e?.name === 'AbortError') return () => {};

      setData((p) => ({ ...p, loading: false, done: false }));
    }

    return () => api.abortRequest(requestId);
  }, [api]);

  const createInsurance = useMemo(() => async (payload: CreateInsuranceCustomer) => {
    const requestId = v4();
    const resp = await api.myPages.createInsuranceV2MyPagesInsurancesV2Post(payload, { headers: { 'X-Request-ID': requestId }, cancelToken: requestId });
    setData({ loading: false, value: [resp.data, ...data.value], done: true });

    if (resp.data.psp_iframe) setPspIframe(resp.data.psp_iframe);
    if (resp.data.psp_external_url) window.location.href = resp.data.psp_external_url;
    // TODO: add logic to redirect to proper URL if there is no iframe

    return resp.data;
  }, [api]);

  useEffect(() => {
    if (!isAuthenticated) return () => {};

    const requestId = v4();
    getInsuranceRulesData(requestId);

    const requestId2 = v4();
    getData(requestId2);

    return () => {
      api.abortRequest(requestId);
      api.abortRequest(requestId2);
    };
  }, [getInsuranceRulesData, isAuthenticated]);

  const value: CustomerInsuranceDataProps = useMemo(() => {
    const safetyInsurances = data.value.filter((v) => v.insurance_group === InsuranceGroup.SAFETY_INSURANCE);
    const pendingSafetyInsurances = safetyInsurances.filter((v) => v.status === InsuranceStatus.INACTIVE && v.substatus === InsuranceSubstatus.WAITING_FOR_CUSTOMER_ACCEPTANCE);

    let defaultInsuredAmount = country === Country.SE ? 3300 : 3400;

    // eslint-disable-next-line curly
    if (blancoMonthlyCost) {
      if (blancoMonthlyCost < 1000) defaultInsuredAmount = 1000;
      else if (blancoMonthlyCost > 10000) defaultInsuredAmount = 10000;
      else defaultInsuredAmount = Math.ceil(blancoMonthlyCost / 100) * 100; // Round to next 100
    }

    return {
      getData,
      createInsurance,

      dataLoading: dataLoading.ongoing || data.loading,
      dataLoaded: dataLoading.done && data.done,
      rules: rules.filter((r) => r.country === country),
      insurances: data.value,

      insuredAmount: pendingSafetyInsurances.length ? pendingSafetyInsurances[0].insured_amount : defaultInsuredAmount,
      encryptedInsuranceId,
      setEncryptedInsuranceId,

      pspIframe,
      setPspIframe,
      setBlancoMonthlyCost,
    };
  }, [customerData, getData, dataLoading, dataLoading, data, rules, country, encryptedInsuranceId, pspIframe, blancoMonthlyCost, setBlancoMonthlyCost]);

  return (
    <CustomerInsuranceDataContext.Provider value={value}>
      {children}
    </CustomerInsuranceDataContext.Provider>
  );
};

export const useCustomerInsuranceData = (): CustomerInsuranceDataProps => useContext(CustomerInsuranceDataContext);

export default CustomerInsuranceDataContext;
