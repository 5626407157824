import { createContext, FC, useContext, useEffect, useMemo, useState } from 'react';

import { useLocation, useNavigate } from 'react-router';

import { Country } from 'src/api/zrm';
import dialmakerGetAppUrl, { DialmakerUrlType } from 'src/utils/dialmaker/urls/getAppUrl';

const DIALMAKER_DESKTOP_API_KEY = 'DialmakerDesktopApi';

interface DialmakerDesktopApi {
  openExternally: (url: string) => void;
  focusWindow: () => void;
}

interface DialmakerDesktopUtilsContextProps {
  dialmakerDesktopApi: DialmakerDesktopApi;
  isDesktopDialmakerApp: boolean;
  openExternally: (url: string, keepWindowFocus?: boolean) => void;
  navigateBack: () => void;
  openAppInBrowser: (id: string, type: DialmakerUrlType, country: Country) => void;
  focusWindow: () => void;
}

const dialmakerDesktopApiDefault = {
  dialmakerDesktopApi: null,
  isDesktopDialmakerApp: false,
  openExternally: () => {},
  navigateBack: () => {},
  openAppInBrowser: () => {},
  focusWindow: () => {},
};

const DialmakerDesktopUtilsContext = createContext<DialmakerDesktopUtilsContextProps>(dialmakerDesktopApiDefault);

export const DialmakerDesktopUtilsProvider: FC = (props) => {
  const { children } = props;

  const navigate = useNavigate();
  const location = useLocation();

  const [isDesktopDialmakerApp, setIsDesktopDialmakerApp] = useState(false);
  const [dialmakerDesktopApi, setDialmakerDesktopApi] = useState<DialmakerDesktopApi>(null);

  const navigateBack = useMemo(() => () => {
    // In case when app was opened from `deep-link` (default app view was search, se  there is not way to use `-1` in navigate)
    if (location?.key === 'default') navigate('/dialmaker-desktop/customer-card');

    navigate(-1);
  }, [location?.key]);

  const openAppInBrowser = useMemo(() => (id: string, type: DialmakerUrlType, country: Country, keepWindowFocus = false) => {
    const url = dialmakerGetAppUrl(id, type, country);

    dialmakerDesktopApi.openExternally(url);

    if (keepWindowFocus) dialmakerDesktopApi.focusWindow();
  }, [dialmakerDesktopApi]);

  const openExternally = useMemo(() => (url: string, keepWindowFocus = false) => {
    dialmakerDesktopApi.openExternally(url);

    if (keepWindowFocus) dialmakerDesktopApi.focusWindow();
  }, [dialmakerDesktopApi]);

  useEffect(() => {
    const dialmakerDesktopApiObject = window[DIALMAKER_DESKTOP_API_KEY];

    if (dialmakerDesktopApiObject) {
      setDialmakerDesktopApi(dialmakerDesktopApiObject);
      setIsDesktopDialmakerApp(true);

      dialmakerDesktopApiObject.handleSearch((event, value) => {
        navigate(`/dialmaker-desktop/search?${value}`);
      });

      dialmakerDesktopApiObject.logInConsole((event, value) => {
        console.log(`%cDIALMAKER: ${value}`, 'color:yellow;background:black;');
      });
    }
  }, []);

  const value: DialmakerDesktopUtilsContextProps = useMemo(() => ({
    isDesktopDialmakerApp,
    dialmakerDesktopApi,
    openExternally,
    navigateBack,
    openAppInBrowser,
    focusWindow: () => dialmakerDesktopApi.focusWindow(),
  }), [isDesktopDialmakerApp, dialmakerDesktopApi, navigateBack, openAppInBrowser, openExternally]);

  return (
    <DialmakerDesktopUtilsContext.Provider value={value}>
      {children}
    </DialmakerDesktopUtilsContext.Provider>
  );
};

export const useDialmakerDesktopUtils = (): DialmakerDesktopUtilsContextProps => useContext(DialmakerDesktopUtilsContext);
